.pc_container {
    border: 1px solid lightgray;
    font-size: medium;
    height: fit-content;
    margin: 4px;
    width: 35ch;
    background-color: white;
}

.pr_container {
    display: grid;
    grid-template-columns: 2fr .5fr;
    justify-content:space-around;
    padding: 5px 10px;
    margin: 1px;
    height: 3rem;
    text-transform:capitalize;
}

.pt_container{
    background-color: rgb(160, 144, 132);
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 1px;
    font-weight: 600;
}

.pc_buttons{
    display: grid;
    margin: 1px;
}

.pc_buttons button{
    font-size: medium;
    background-color: oldlace;
    border: 1px solid lightgray;
    cursor: pointer;
    padding: 5px;
    color: black;

}

.rem_btn{
    margin-left: 1px;
}

.pcl_container {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;   
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
}

.products_title h4{
    padding-left: 10px;
    color: rgb(65, 30, 5);
}
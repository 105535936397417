.user_form label, input {
    font-size: medium;
    margin-bottom: 10px;
}

.user_form label {
    display: block;
    font-weight: 500;
}
.user_form input {
    width: 40ch;
    background-color: oldlace;
    border: 1px solid black;
    height: 2rem;
    padding: 5px;
    border-radius: 5px;
}

.user_form button {
    font-size: large;
    font-weight: bold;
    padding: 15px;
    background-color: rgb(83, 185, 219);
    color: white;
    border-radius: 25px;
    border: none;
    width: fit-content;
    height: 3rem;
    justify-self: right;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user_form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.user_form form {
    padding: 10px;
    width: min-content;
    
}

.user_container {
    border-radius: 15px;
    background-color: white;
    opacity: .9;
    margin: 50px;
    max-width: 95%;
}
.message_container {
    color: red;
    font-size: medium;
    text-align: right;
}

.btn_msg {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}


.user_status_container a {
    color: antiquewhite;
    font-size: medium;
    text-decoration: none;
}

.uProfile_header{
    padding: 10px;
    color: rgb(65, 30, 5);
    text-align: center;
    font-size: larger;
}
.register_check{
    margin-top: 2px;
    font-size: small;
}

@media only screen and (max-width: 580px) {
    .user_status_container a {
        font-size: large;
    }  
    .active_user {
        font-size: large;
        margin-left: 35px;
    }  
}
.nav_container {
    display: flex;
    justify-content: space-between;
    background-color: oldlace;
    align-items: center;
    padding: 15px 10px;
    font-size: large;
    list-style: none;
    z-index: 100;

}

.nav_link_active_false{
    text-decoration: none;
    color: rgb(65, 30, 5);
    margin-right: 1em;
}

.nav_link_active_true{
    text-align: center;
    text-decoration: none;
    background-color: rgb(83, 185, 219);
    color: rgb(65, 30, 5);
    padding: 3px 10px;
    border-radius: 15px;
    margin-right: 1em;
}

/* .dot{
    display: inline-block;
    background-color: white;
    color: black;
    height: 26px;
    width: 26px;
    border-radius: 45%;
    text-align: center;
    border: 1px solid black;
} */
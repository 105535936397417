.thankYou_container{
    border: 1px solid lightgray;
    width: fit-content;
    margin: 10px auto;
    padding: 20px;
    text-align: center;
    font-weight: 500;
    background-color: white;
    opacity: .9;
    border-radius: 20px;
}

.thankYou_container button{
    font-size: medium;
    font-weight: bold;
    padding: 5px;
    background-color: rgb(83, 185, 219);
    color: black;
    border-radius: 25px;
    border: none;
    width: 90%;
    height: 3rem;
    justify-self: right;
    cursor: pointer;
    margin-top: 20px;
}
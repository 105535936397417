.banner_container {
    font-size:x-large;
    color:rgb(65, 30, 5);
    background-color: white;
    opacity: .9;
    height: fit-content;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    max-width: 95%;
}

.guest-login{
  cursor: pointer;
  background-color: rgb(65, 30, 5);
  color: white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.guest-loggedIn{
  visibility: hidden;
}

.guest-notLoggedIn{
  visibility: visible;
}

.store-title{
  color: rgb(83, 185, 219)
}

@media only screen and (max-width: 580px) {
    .banner_container {
      font-size:medium;
      color:rgb(65, 30, 5);
      background-color: white;
      opacity: .9;
      height: fit-content;
      border-radius: 20px;
      padding: 20px;
      text-align: center;
      max-width: 95%;
      margin-bottom: 100px;
    }
}
.ccl_container {
    background-color: white;
    opacity: .9;
    padding-top: 10px;
    border-radius: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 95%;
}
.cc_container {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr .5fr 1fr;
    justify-content:space-around;
    margin: auto;
    margin-top: 10px;
    padding: 5px 10px;
    border-top: 1px solid lightgray;
    font-size: small;
}
.cc_container button{
    margin-left: 5px;
    font-size: small;
    background-color: oldlace;
    border: 1px solid lightgray;
    color: black;
    cursor: pointer;
    height: fit-content;
    padding: 4px;
}
.cartItemDesc{
    text-transform: capitalize;
}
.cartItemsQty{
    text-align: center;
}
.ccl_container h4 {
    /* padding-left: 10px; */
    color: rgb(65, 30, 5);
    font-size: large;
}
.cart_header{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 90%;
    padding: 10px;
    /* border: 1px solid lightgray; */
    margin: auto;
    min-width: 30ch;
}

.cart_header button {
    font-size: large;
    font-weight: bold;
    padding: 5px;
    background-color: rgb(83, 185, 219);
    color: black;
    border-radius: 25px;
    border: none;
    width: 80%;
    height: 3rem;
    justify-self: right;
    cursor: pointer;
}

.cartItems_container h5 {
    text-align: center;
    font-size: large;
}
.cartItemsList{
    border: 1px solid lightgray;
    width: 90%;
    margin: 20px auto;
    border-radius: 10px;
}
.cart_empty{
    text-align: right;
}

@media only screen and (max-width: 580px) {
    .cc_container {
        grid-template-columns: 1fr 1.25fr 1fr .75fr 1fr;
    }
}
.header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    background-color: rgb(65, 30, 5);
    color: antiquewhite;
    font-size:x-large;
}

@media only screen and (max-width: 580px) {
    .header_container {
        font-size:larger;
    }
}
